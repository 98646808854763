import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "d3blackbox-magic-trick--render-anything-in-30-seconds"
    }}>{`D3blackbox magic trick – render anything in 30 seconds`}</h1>
    <Vimeo id={424604666} mdxType="Vimeo" />
    <p>{`Let me show you a magic trick. 30 seconds to take a random D3 piece of code and
add it to your React project.`}</p>
    <p>{`We can try it on the example barchart from before.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/barchart-example.png",
        "alt": "An example D3 barchart"
      }}></img></p>
    <p>{`You can
`}<a parentName="p" {...{
        "href": "https://cdn.rawgit.com/mbostock/3885304/raw/a91f37f5f4b43269df3dbabcda0090310c05285d/index.html"
      }}>{`try it online`}</a>{`.
When you hover on a bar, it changes color. Pretty neat.`}</p>
    <p>{`I recommend you follow along in a CodeSandbox. If you fork the
`}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/5v21r0wo4x"
      }}>{`react-d3-axis-hoc CodeSandbox`}</a>{` that will
be easiest.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/5v21r0wo4x",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`You should already have the `}<inlineCode parentName="p">{`D3blackbox`}</inlineCode>{` HOC. If you don't, make a new file and
paste it in.`}</p>
    <p>{`With your HOC ready, create a new file in CodeSandbox. Call it `}<inlineCode parentName="p">{`Barchart.js`}</inlineCode>{`.`}</p>
    <p>{`Add your imports:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from "react"
import D3blackbox from "./D3blackbox"
import * as d3 from "d3"
`}</code></pre>
    <p>{`This gives you React, our HOC, and D3.`}</p>
    <p>{`Now right-click view code on that barchart and copy the code. Wrap it in a
`}<inlineCode parentName="p">{`D3blackbox`}</inlineCode>{` call. Like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Barchart = D3blackbox(function () {
  var svg = d3.select("svg"),
    margin = { top: 20, right: 20, bottom: 30, left: 40 },
    width = +svg.attr("width") - margin.left - margin.right,
    height = +svg.attr("height") - margin.top - margin.bottom

  var x = d3.scaleBand().rangeRound([0, width]).padding(0.1),
    y = d3.scaleLinear().rangeRound([height, 0])

  var g = svg
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

  d3.tsv(
    "data.tsv",
    function (d) {
      d.frequency = +d.frequency
      return d
    },
    function (error, data) {
      if (error) throw error

      x.domain(
        data.map(function (d) {
          return d.letter
        })
      )
      y.domain([
        0,
        d3.max(data, function (d) {
          return d.frequency
        }),
      ])

      g.append("g")
        .attr("class", "axis axis--x")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x))

      g.append("g")
        .attr("class", "axis axis--y")
        .call(d3.axisLeft(y).ticks(10, "%"))
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 6)
        .attr("dy", "0.71em")
        .attr("text-anchor", "end")
        .text("Frequency")

      g.selectAll(".bar")
        .data(data)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", function (d) {
          return x(d.letter)
        })
        .attr("y", function (d) {
          return y(d.frequency)
        })
        .attr("width", x.bandwidth())
        .attr("height", function (d) {
          return height - y(d.frequency)
        })
    }
  )
})

export default Barchart
`}</code></pre>
    <p>{`That should throw some errors. We have to change the `}<inlineCode parentName="p">{`d3.select`}</inlineCode>{` and get
`}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`height`}</inlineCode>{` from props.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Barchart = D3blackbox(function () {
  // Delete the line(s) between here...
  var svg = d3.select("svg"),
  // ...and here.
  // Insert the line(s) between here...
  var svg = d3.select(this.anchor.current)
  // ...and here.
      margin = {top: 20, right: 20, bottom: 30, left: 40},
      // Delete the line(s) between here...
      width = +svg.attr("width") - margin.left - margin.right,
      height = +svg.attr("height") - margin.top - margin.bottom;
      // ...and here.
      // Insert the line(s) between here...
      width = +this.props.width - margin.left - margin.right,
      height = +this.props.height - margin.top - margin.bottom;
      // ...and here.
`}</code></pre>
    <p>{`Most D3 examples use a global `}<inlineCode parentName="p">{`svg`}</inlineCode>{` variable to refer to their drawing area
– the SVG. Change that to the element you want, your anchor, and the whole
visualization should render in there.`}</p>
    <p>{`We also replaced reading width and height from the SVG element to getting them
from props. This makes our component more reusable and better follows best
practices.`}</p>
    <p>{`Next step is to change where our barchart gets its data. Gotta use the public
URL.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`//Delete the line(s) between here...
d3.tsv("data.tsv", function(d) {
// ...and here.
// Insert the line(s) between here...
d3.tsv("https://swizec.github.io/dl/barchart.tsv", function(d) {
// ...and here.
    d.frequency = +d.frequency;
    return d;
// Delete the line(s) between here...
  }, function(error, data) {
    if (error) throw error;
// ...and here.
// Insert the line(s) between here...
  }).then(function(data) {
// ...and here.
`}</code></pre>
    <p>{`Same link, absolute version. And we updated the callback-based code to use the
D3v5 promises version. That's the most disruptive change going from v4 to v5 I
believe.`}</p>
    <p>{`That's it. You now have a Barchart component that renders the example barchart
from D3's docs.`}</p>
    <p>{`You can use it like this 👇 I recommend adding this code to the main
App component that CodeSandbox creates for you.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Barchart from "./Barchart"

// ...
return (
  <svg width="800" height="600">
    <Barchart x={10} y={10} width={400} height={300} />
  </svg>
)
`}</code></pre>
    <p>{`But like I said, don't use this in production. It's great for quick prototypes,
trying stuff out, or seeing how an existing visualization might fit your app.`}</p>
    <h2 {...{
      "id": "a-note-about-d3blackbox"
    }}>{`A note about D3blackbox`}</h2>
    <p>{`To make your life easier, I have open sourced my version of the D3blackbox HOC.
You can read more about it at `}<a parentName="p" {...{
        "href": "https://d3blackbox.com"
      }}>{`d3blackbox.com`}</a></p>
    <p>{`Works the same as the HOC we just built together, adds the anchor ref, props,
and state to function arguments so it's easier to use. No need to mess with
`}<inlineCode parentName="p">{`this`}</inlineCode>{` if you don't want to :)`}</p>
    <p>{`Install it from npm:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ npm install d3blackbox
`}</code></pre>
    <p>{`Then use it as you would your own HOC:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from "react"
import D3blackbox from "d3blackbox"
import * as d3 from "d3"

const Barchart = D3blackbox(function (anchor, props, state) {
  const svg = d3.select(anchor.current)
  // the rest of your D3 code
})

export default Barchart
`}</code></pre>
    <p>{`The function you pass into `}<inlineCode parentName="p">{`D3blackbox`}</inlineCode>{` is still your full D3 render. Except
now you can access important values directly without using `}<inlineCode parentName="p">{`this`}</inlineCode>{`.`}</p>
    <p>{`You can use my D3blackbox or build your own. What's important is that you now
understand how higher order components work.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      